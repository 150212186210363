<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card outlined class="primary--border">
                        <v-tabs
                                v-model="tab"
                                icons-and-text>
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#tab-1">
                                Monthly Bill Generate
                                <v-icon>calendar_today</v-icon>
                            </v-tab>
                            <v-tab href="#tab-2">
                                Monthly Collection
                                <v-icon>calendar_today</v-icon>
                            </v-tab>
                            <v-tab href="#tab-3">
                                Students Collection
                                <v-icon>person</v-icon>
                            </v-tab>

                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item
                                    :key="1"
                                    value="tab-1">
                                <v-card flat>
                                    <monthly-generate-bill></monthly-generate-bill>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item
                                    :key="2"
                                    value="tab-2">
                                <v-card flat>
                                    <monthly-collection></monthly-collection>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item
                                    :key="3"
                                    value="tab-3">
                                <v-card flat>
                                    <students-collection></students-collection>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import {getPropertyFromArrayObject, nepNumberToEng, engNumberToNep} from '@/library/helpers'
    import MonthlyCollection from "./pages/monthly_collection";
    import StudentsCollection from "./pages/students_collection";
    import MonthlyGenerateBill from "./pages/monthly_generate_bill";


    const NepaliDate = require('nepali-date');


    export default {
        components: {MonthlyCollection, StudentsCollection, MonthlyGenerateBill},

        data: () => ({
            // form: new Form({}, '/api/attendance/daily'),
            // grades: [],
            tab: null,
            // printUrl: '',
            // grade: '',
            // section: '',
            // assignment_date: new NepaliDate(new Date()).format('YYYY-MM-DD'),
            //
            // sections: [],
            // model: '',
            // loadingGrade: false,
            // loadingSection: false,
            // month: '',
            // attendanceDay: [],
            // days: [],
            // years: [{value: '2075', text: '2075'}, {value: '2076', text: '2076'}],
            // saal: '',
            // schoolEvents: [],
            // schoolEventDates: [],
            // data: [],
            // pagination: {
            //     rowsPerPage: 100,
            //     sortBy: 'roll'
            // }
        }),

        computed: {
            ...mapState(['batch']),
        },
        mounted() {
            // this.getGrades();
        },
        watch: {
            // 'month': function (v) {
            //     if (v) {
            //         this.get();
            //         this.getSchoolEvents()
            //     }
            //
            // },
            // 'section': function () {
            //     this.saal = '';
            //     this.month = '';
            // },
            // 'grade': function () {
            //     this.saal = '';
            //     this.month = '';
            // },
            // 'saal': function () {
            //     this.month = '';
            // }

        },
        methods: {
            // nepNumberToEng,
            // queryString() {
            //     let json = this.pagination;
            //     return '?' +
            //         Object.keys(json).map(function (key) {
            //             if (![null, undefined].includes(json[key]))
            //                 return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
            //         }).join('&') + '&gradeId=' + this.grade + '&sectionId=' + this.section + '&month=' + this.month + '&year=' + this.saal;
            // },
            //
            //
            // get(params) {
            //     let query = [null, undefined].includes(params) ? this.queryString() : params;
            //     this.form.get(null, query).then(({data}) => {
            //         this.data = data.students;
            //         this.attendanceDay = data.attendance_day;
            //         this.printUrl = data.download_url;
            //     });
            //
            // },


            // getGrades() {
            //     this.sections = [];
            //     this.section = '';
            //     this.loadingGrade = true;
            //     this.$rest.get('/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true').then(({data}) => {
            //         this.grades = data.data.map(item => {
            //             return {value: item.id, text: item.name}
            //         });
            //     }).finally(err => {
            //         this.loadingGrade = false;
            //     })
            // },
            // getSections() {
            //     this.section = '';
            //     this.sections = [];
            //     this.loadingSection = true;
            //     this.$rest.get('/api/section?batch=' + this.batch.id + '&grade=' + this.grade).then(({data}) => {
            //         this.sections = data.data.map(item => {
            //             return {value: item.id, text: item.name}
            //         });
            //     }).finally(() => {
            //         this.loadingSection = false;
            //     })
            // },
            printAttendance() {
                window.open(this.printUrl);
            }
        }
    }
</script>
<style lang="scss">

</style>